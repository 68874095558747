<template>
  <div>
    <div class="d-flex justify-content-between align-items-center p-0">
      <div class="col-auto">
        <span class="p-2">
          Remise Référence Nº :
          <span class="fw-bold">
            {{ $route.params.reference }}
          </span>
        </span>
      </div>
      <div class="col-auto row">
        <div
          v-if="discount.reference && userRole == 'Admin'"
          class="col-auto p-1"
        >
          <button
            @click="
              $router.push({
                name: 'discounts-edit',
                params: { reference: discount.reference },
              })
            "
            class="btn border"
          >
            <i class="bi bi-pencil-square text-secondary fs-4"></i> Modifier
          </button>
        </div>
        <div v-if="discount.reference" class="col-auto p-1">
          <button @click="print(discount.reference)" class="btn border">
            <i class="bi bi-printer text-secondary fs-4"></i> Imprimer
          </button>
        </div>

        <div
          v-if="discount.reference && userRole == 'Admin'"
          class="col-auto p-1"
        >
          <button @click="remove(discount)" class="btn border">
            <i class="bi bi-trash text-secondary fs-4"></i> Supprimer
          </button>
        </div>
        <div class="col-auto p-1">
          <button @click="refresh()" class="btn border">
            <i class="bi bi-arrow-clockwise text-secondary fs-4"></i> Actualiser
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div class="row align-items-stretch" id="printMe">
      <div class="col-6">
        <!-- customer name -->
        <div class="row justify-content-between">
          <div class="col p-1">Client</div>
          <div class="col p-1 fw-bold">
            <span
              v-if="discount.customer"
              :class="
                discount.customer.fullName.includes('Bloqué')
                  ? 'text-danger'
                  : ''
              "
              class="fw-bold"
            >
              {{ discount.customer.fullName }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Nº de Téléphone</div>
          <div class="col p-1 fw-bold">
            <span v-if="discount.customer" class="fw-bold">
              {{ discount.customer.phone }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Nom Commercial (Société)</div>
          <div class="col p-1 fw-bold">
            <span v-if="discount.customer" class="fw-bold">
              {{ discount.customer.society }}
            </span>
          </div>
        </div>
      </div>

      <!-- paiments -->
      <div class="col-6 justify-content-between">
        <div class="row">
          <div class="col p-1">Remise :</div>
          <div v-if="discount.amount" class="col p-1 fw-bold">
            {{ discount.amount | toFixedWithSpace }} Dh
          </div>
          <div v-else class="col p-1 fw-bold text-danger">----------</div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Impayé :</div>
          <div v-if="discount.remainingAmount" class="col p-1 fw-bold">
            {{ discount.remainingAmount | toFixedWithSpace }}
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div class="col-auto p-1">Remarque :</div>
    <div class="col p-1 fw-bold">
      {{ discount.remark }}
    </div>
    <br />
    <div>
      Le montant de la remise ci-dessus a été réparti sur les factures
      ci-dessous :
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Facture Réf</th>
          <th scope="col">Date</th>
          <th scope="col">M. Facture</th>
          <th scope="col">M. Payé</th>
          <th scope="col">M. Remise</th>
          <th scope="col">M. Impayé</th>
          <th scope="col">Créer par</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(discountSale, index) in discount.discount_sales"
          :key="index++"
        >
          <td>
            {{ index }}
          </td>
          <td>
            {{ discountSale.sale_reference }}
          </td>

          <td>{{ discountSale.date }}</td>

          <td>
            <span v-if="discountSale.sale">
              <span
                v-if="discountSale.sale.totalPriceTTC"
                class="bg-primary rounded px-2 py-1 text-white fw-bold"
              >
                {{ discountSale.sale.totalPriceTTC }}
              </span>
            </span>
          </td>

          <td>
            <span v-if="discountSale.sale">
              <span
                v-if="discountSale.sale.amountPaid"
                class="bg-success rounded px-2 py-1 text-white fw-bold"
              >
                {{ discountSale.sale.amountPaid | toFixedWithSpace }}
              </span>
            </span>
          </td>

          <td>
            <span
              v-if="discountSale.amount"
              class="bg-secondary rounded px-2 py-1 text-white fw-bold"
            >
              {{ discountSale.amount | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-danger">
            <span v-if="discountSale.sale">
              <span
                v-if="discountSale.sale.amountUnpaid"
                class="bg-danger rounded px-2 py-1 text-white fw-bold"
              >
                {{ discountSale.sale.amountUnpaid | toFixedWithSpace }}
              </span>
            </span>
          </td>
          <td>
            <span v-if="discountSale.user">
              {{ discountSale.user.name }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {};
  },

  mounted() {
    this.$store.dispatch("discount/show", this.$route.params.reference);
  },
  computed: {
    ...mapGetters("discount", {
      discount: "getDiscount",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("discount/show", this.$route.params.reference);
    },

    async remove(data) {
      let discount = data;
      await this.$confirm({
        message:
          "Vous voulez le paiement  " +
          discount.reference +
          "  Montant de : " +
          discount.amount,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("discount/destroy", discount.reference);
          }
        },
      });
    },

    async print(reference) {
      await this.$store.dispatch("discount/printReceipt", reference);
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
};
</script>
